.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.margin- {
  &tb {
    margin: 1rem 0;
  }
}

section.layout {
  min-height: 100vh;
}

main.ant-layout-content {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-areas: "left-aside main right-aside";
}

aside {
  &.left {
    grid-area: left-aside;
  }

  &.right {
    grid-area: right-aside;
  }
}

div.content {
  grid-area: main;

  &.full-width {
    grid-area: auto / span 3;
  }
}

div.modal-footer {
  padding-bottom: 2rem;

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;

    >button:last-child {
      margin-left: 1rem;
    }
  }
}

div.svc-creator {
  --primary: #657c91;
  --secondary: #88949e;
  --secondary-back-light: #c5cdd1;
  --secondary-light: #c5cdd1;

  font-family: -apple-system, BlinkMacSystemFont, "ProximaNova", "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  & * {
    font-family: -apple-system, BlinkMacSystemFont, "ProximaNova", "Segoe UI",
      Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  }

  .svc-tabbed-menu-item--selected,
  .svc-side-bar__container-header,
  .sd-title.sd-container-modern__title {
    box-shadow: inset 0px -2px 0px #ed1a3b;
    border: none;
  }

  .sd-header__text {

    & h1,
    & h2 {
      color: #ed1a3b;
    }

    & h3,
    & h4,
    & h5 {
      color: rgba(0, 0, 0, 0.88);
    }
  }

  .red-text {
    color: #ed1a3b;
  }

  .sd-input:focus {
    box-shadow: 0 0 0 2px #657c91,
      inset 0px 1px 2px var(--border-inside, rgba(0, 0, 0, 0.16));
  }

  .sv-action-bar-item--active .sv-action-bar-item__icon use {
    fill: #ed1a3b;
  }

  .sv-button-group__item--selected {
    color: #ed1a3b;
  }

  .spg-checkbox--checked .sv-boolean__svg {
    fill: #ed1a3b;
  }

  .svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):focus .sv-svg-icon use,
  .svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):hover .sv-svg-icon use {
    fill: #ed1a3b;
  }

  .svc-page-navigator-item-content:not(.svc-page-navigator-item--disabled) .svc-page-navigator-item__banner .svc-page-navigator-item__dot {
    background: #ed1a3b;
  }

  .svc-page-navigator-item--selected .svc-page-navigator-item__dot {
    border: calc(0.25 * var(--base-unit, 8px)) solid #ed1a3b;
  }

  .svc-string-editor:focus-within .svc-string-editor__border {
    outline: calc(0.25 * var(--base-unit, 8px)) solid #657c91;
  }
}

div.sv-popup {

  .sv-list__item--selected,
  .sv-list__item--selected:hover,
  .sv-list__item--selected:focus {
    background-color: #ed1a3b;
    color: var(--background, #fff);
  }

  .sv-list__input:focus {
    border: 1px solid #ed1a3b;
  }

  .sv-action-bar-item--active .sv-action-bar-item__icon use {
    fill: #ed1a3b;
  }
}

ul.ant-menu.ant-menu-dark {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyODMuNDcgMTA4Ljg2Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPkJET19sb2dvX3doaXRlPC90aXRsZT48ZyBpZD0iQXJ0d29yayI+PHBvbHlnb24gY2xhc3M9ImNscy0xIiBwb2ludHM9IjM3LjIzIDgyLjc4IDM3LjI0IDgyLjc4IDM3LjI0IDgyLjc4IDM3LjIzIDgyLjc4Ii8+PHBvbHlnb24gY2xhc3M9ImNscy0xIiBwb2ludHM9IjExNS40NiA4Mi43OCAxMTUuNDYgODIuNzggMTE1LjQ2IDgyLjc4IDExNS40NiA4Mi43OCIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTUzLjc3LDQ1LjEyaDkuODdjMTYuMjMsMCwyNC41LDMuNTUsMjQuNSwxMywwLDguNDItNi42NCwxMi0xNywxMkg1NS4zTDM3LjI0LDgyLjc4SDcxYzIwLjIsMCwzMy44MS03LjI4LDMzLjgxLTI0LjExLDAtMTQuNjgtMTItMjAuNDUtMjIuNzktMjAuNDUsNy40MywwLDE2LjItNi42NSwxNi4yLTE3Ljk0Uzg3Ljg4LjksNzYuMTIuOUgzNC4yMmwzLDYuNzFWNzYuODlMNTMuNzcsNjUuMzFabTAtMzEuNTZINzEuMWM0Ljc2LDAsMTAuNzgsMS4xMywxMC43OCw5LjIxcy04LjYxLDExLjM3LTE1LDExLjM3SDUzLjc3WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwKSIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTEzMiwxMy41NmgxMS4yNmM0LjY1LDAsMjguMzQsMS4xNywyOC4zNCwyOC4yOCwwLDMwLjk0LTI4LjM0LDI4LjI5LTI4LjM0LDI4LjI5aC05LjcyTDExNS40Niw4Mi43OEgxNDcuOWMyMC4yLDAsNDAuOTItMTIuNDIsNDAuOTItNDAuOTRDMTg4LjgyLDE2LjU2LDE3MC43My45LDE0Ny45LjlIMTEyLjQ0bDMsNi43MVY3Ni44OUwxMzIsNjUuMzFaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDApIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMTk2LjQ2LDQxLjg0YzAsMzIuNjcsMjYuNCw0MS44NSw0My41LDQxLjg1czQzLjUxLTkuMTgsNDMuNTEtNDEuODVTMjU3LjA3LDAsMjQwLDAsMTk2LjQ2LDkuMTgsMTk2LjQ2LDQxLjg0Wm0xNy4yMiwwYzAtMjMuMTUsMTUuOTUtMjkuNjUsMjYuMjgtMjkuNjVzMjYuMjksNi41LDI2LjI5LDI5LjY1UzI1MC4zLDcxLjUsMjQwLDcxLjUsMjEzLjY4LDY1LDIxMy42OCw0MS44NFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiLz48cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMCAwLjkxIDAgMTAyLjk3IDE2LjE1IDkxLjY2IDE2LjE1IDAuOTEgMCAwLjkxIi8+PHBvbHlnb24gY2xhc3M9ImNscy0xIiBwb2ludHM9IjE5LjE3IDk1LjQ0IDAgMTA4Ljg2IDI4Mi41NyAxMDguODYgMjgyLjU3IDk1LjQ0IDE5LjE3IDk1LjQ0Ii8+PC9nPjwvc3ZnPg==");
  padding-left: 5.5rem;
  background-color: #2d2d2d;
  background-size: 70px;
  background-repeat: no-repeat;
  background-position: 0 50%;
}

ul.ant-menu.ant-menu-dark:before {
  content: "Capture";
  font-size: 1.25rem;
  margin-right: 3rem;
  color: white;
}

header.ant-layout-header {
  display: grid;
  grid-template-areas: "header-left-aside header-main header-right-aside";
  grid-template-columns: 1fr 3fr 1fr;
  background: #2d2d2d;
}

ul.ant-menu.ant-menu-dark {
  grid-area: header-main;
  box-shadow: none;
}

ul.ant-menu.ant-menu-dark,
div.content {
  min-width: 600px;
}

div.content>h2 {
  margin-top: 1.5rem;
}

/* Scrollable regions within SurveyJS editor */
.svc-side-bar__container-content {
  height: calc(100vh - var(--base-unit, 8px) * 8);
}

.svc-tab-designer {
  height: 100vh;
}

/*Capture page*/
.controls {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.controls input {
  margin: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='64 64 896 896' focusable='false' data-icon='search' width='1em' height='1em' fill='%239e9e9e' aria-hidden='true'%3E%3Cpath d='M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  padding-right: 2rem;
}

// .ant-dropdown-trigger:after {
//   content: "";
//   display: inline-flex;
//   height: 1em;
//   width: 1rem;
//   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 410 226'%3E%3Cpath fill='%23ed1a3b' d='M0 30.334 33.874 0 204.55 161.012 375.925 0 410 30.334 204.549 226 0 30.334Z'/%3E%3C/svg%3E");
//   background-repeat: no-repeat;
//   background-position: right 0 bottom 0;
//   background-size: 1em;
//   margin-left: 0.3em;
// }

.ant-dropdown-menu-item.information:hover {
  background-color: #fff !important;
  cursor: default;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.ant-tabs-nav-list {
  flex: 1;
  gap: 0.25em;
}

.d-flex.flex-row-reverse {
  justify-content: space-between;
}

.ant-tabs-tab-disabled {
  color: inherit;
}

.ant-tabs-tab {
  background: #e5e5e5;
  margin: 0;
  flex: 1;
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0;
}

.ant-tabs-nav .ant-tabs-tab-active {
  background: white;
}

.ant-tabs>.ant-tabs-nav,
.ant-tabs>div>.ant-tabs-nav {
  margin: 0;
}

.ant-tabs div.content {
  padding-top: 0 !important;
}

.ant-tabs h2 {
  font-weight: bold;
}

.ant-tabs h2 .highlighted-text {
  color: #ed1a3b;
}

.ant-tabs-tab-btn,
.ant-tabs-tab-remove {
  width: 100%;
  text-align: center;
}

.ant-tabs h2 .highlighted-text {
  color: #ed1a3b;
}

.additionalContent {
  margin-top: 2rem;
}

.flex-card .ant-card-body {
  display: flex;
}

.flex-card .ant-card-body button {
  margin-left: 1rem;
}

.embedInWebpageContent .ant-card-body {
  display: flex;
  flex-direction: column;
}

.embedInWebpageContent .ant-card-body button {
  margin-left: auto;
}

.ant-table-content {
  overflow: auto;
}

[id="root"],
.bdo-page {
  min-height: 100%;
  height: 100%;
}

.bdo-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--BG-Grey);
  padding: 2rem;
}

/* Spinner loader */
.bdo-spinner {
  border: 0.688rem solid transparent;
  border-top: 0.688rem solid var(--BDO-Red);
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.bdo-spinner-container {
  display: flex;
  justify-content: center;
}

.mt-3 {
  margin-top: 1rem;
}

.controlsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mb-0 {
  margin-bottom: 0;
}

.custom-link-button {
  padding: 0;
  margin-left: 2rem;
  color: #ed1a3b;
}

.user-dropdown {
  cursor: pointer;
  margin-right: 10px;
}

.sd-boolean__switch .sd-boolean__thumb .sd-boolean__thumb-text .sv-string-viewer p{
  margin-bottom:0px
}
.sd-boolean__label .sv-string-viewer p{
  margin-bottom:0px
}

.sd-progress.sd-body__progress{
  display: list-item;
}

.fileUploader {
 min-height: 200px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px dashed var(--border, #d6d6d6);
}

.fileDownloadContainer {
  min-height: 200px;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px dashed var(--border, #d6d6d6);
}

.fileDownloadContainer ul{
padding-top: 5px;
}

.fileDownloadContainer li {
  margin: 8px 10px;
  display: flex;
  flex-direction: row;
  background-color: var(--Success-Green);
  color: white;
  padding: 0px 5px;
  height: 46px;
}

.fileinfo{
  text-overflow: ellipsis;
  overflow: hidden;
  width: 95%;
  font-size: 14px; 
  padding: 5px;
  display: inline-block;
}

.fileinfo-sub{        
  font-size: 12px;
  display: block;
  line-height: .7;
  opacity: .75; 
  display: block;
}

.download-icon {
  margin-top: 8px;
  padding: 5px;
  height: 25px;
  width: 35px;
  mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItZG93bmxvYWQiPjxwYXRoIGQ9Ik0yMSAxNXY0YTIgMiAwIDAgMS0yIDJINWEyIDIgMCAwIDEtMi0ydi00Ij48L3BhdGg+PHBvbHlsaW5lIHBvaW50cz0iNyAxMCAxMiAxNSAxNyAxMCI+PC9wb2x5bGluZT48bGluZSB4MT0iMTIiIHkxPSIxNSIgeDI9IjEyIiB5Mj0iMyI+PC9saW5lPjwvc3ZnPg==");
  mask-repeat: no-repeat;  
  background-color: #fff;
  cursor: pointer;
  pointer-events: auto;
  &:hover {
      background: rgba(#fff, .5);
  }
}

.fileinfo-icon
{
  height: 25px;
  width: 25px;
  fill: #fff;
  margin: 8px 5px 0 8px;
}

.fileinfo-status
{
  margin: 5px 10px;
  padding: 3px 5px;
}

.fileinfo-status-Failure
{
  margin: 5px 10px;
  padding: 3px 5px;
  color: var(--burgundy);
}

.uploadIcon{
  background-color: #333333;
  width: 35px;
  height: 30px;
  mask-repeat: no-repeat;  
  mask-image: url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 91 91'%3E%3Cpath d='M34.1243 25.7979H41.6297V60.1565C41.6297 60.6034 41.9954 60.969 42.4422 60.969H48.536C48.9829 60.969 49.3485 60.6034 49.3485 60.1565V25.7979H56.8743C57.5547 25.7979 57.9305 25.0159 57.5141 24.4878L46.1391 10.0862C46.0631 9.98911 45.966 9.91057 45.8551 9.85656C45.7443 9.80255 45.6226 9.77448 45.4993 9.77448C45.376 9.77448 45.2543 9.80255 45.1434 9.85656C45.0325 9.91057 44.9354 9.98911 44.8594 10.0862L33.4844 24.4776C33.068 25.0159 33.4438 25.7979 34.1243 25.7979V25.7979ZM82.6711 57.1097H76.5774C76.1305 57.1097 75.7649 57.4753 75.7649 57.9222V73.5628H15.2336V57.9222C15.2336 57.4753 14.868 57.1097 14.4211 57.1097H8.32739C7.88052 57.1097 7.51489 57.4753 7.51489 57.9222V78.0315C7.51489 79.8292 8.96724 81.2815 10.7649 81.2815H80.2336C82.0313 81.2815 83.4836 79.8292 83.4836 78.0315V57.9222C83.4836 57.4753 83.118 57.1097 82.6711 57.1097Z'/%3E%3C/svg%3E" );  
 }

 .dropAreaContainer{
  display:flex;
  justify-content: center;
 }

 .dropAreaText{
  margin-top: 5px;
  color: black;
  font-size: medium !important;  
 }

 .uploadAreaInfo{
  color: gray !important;
 }

 .filepond--root {
  font-family: -apple-system, BlinkMacSystemFont,'ProximaNova', 'Segoe UI', Roboto, Helvetica, Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
 
.filepond--file-info .filepond--file-info-main {
  font-size: 14px;
}

.filepond--file-info .filepond--file-info-sub {
  opacity: .75;
  font-size: 12px;
}

.filepond--file-status .filepond--file-status-main {
  margin-top: 3px;
  font-size: 14px;
  line-height: 1.2;
}

.filepond--file-status .filepond--file-status-sub {
  font-size: 12px;
  opacity: .75;
  transition: opacity .25s ease-in-out;
}

.filepond--item-panel {
  background-color: var(--Success-Green);
  border-radius: 0 !important;
}

.btn-align-right {
  margin-left: auto;
  margin-right: 0px;
  display: block;
}

[data-filepond-item-state='processing-complete'] {
  .filepond--item-panel {
      background-color: var(--Success-Green);
  }
}

[data-filepond-item-state*='invalid'],
[data-filepond-item-state*='error'] {
  .filepond--item-panel {
      background-color: var(--burgundy);
  }
}

.right-align
{
  float:inline-end;
}

.right-align > button{background:#ed1a3b;
}