.errorPage {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  background-color: #fff;
  padding: 30px;

  .errorContainer {
    min-width: 600px;
    max-width: 600px;
    margin: auto;
    text-align: center;
    padding: 20px;

    .warningIcon {
      max-width: 200px;
      margin: 0 auto;
    }

    .actionButton {}

    .appVersion {
      color: #6f6f6f;
    }
  }
}